.call-controls-container {
    position: absolute;
    bottom: 2em;
    min-height: 5em;
    width: 100%;
    text-align: center;
}
.controlButtonsGroup button {
    margin: 1em;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.7), 2px 2px 5px rgba(0, 0, 0, 0.7);

}