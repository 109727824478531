body {
    background-color: #f3f4f6;
}

.connectPageMain {
    height: calc(100vh - 64px);
    display: table-cell;
    width: 200vh;
    vertical-align: middle;
}

.qrCode-container {
    height: 140px;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 8px;
}

.qrCode-container img {
    margin-top: -4px;
}